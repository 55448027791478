<template>
    <div class="home">
        <!-- 头部 -->
        <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->

        <!-- 头部大图 -->
        <div class="headImg">
            <img src="@/assets/xsmsHead.png" alt="">
        </div>

        <!-- tabs -->
        <div class="tabs">
            <!-- <div class="title" v-show="showImg">
                <div class="img">
                    <div class="text">今日秒杀</div>
                </div>
                <div class="img2" @click="ming">
                    <div class="text">明日即将开始</div>
                </div>
            </div>
            <div class="title2" v-show="!showImg">
                <div class="img" @click="ming">
                    <div class="text">今日秒杀</div>
                </div>
                <div class="img2">
                    <div class="text">明日即将开始</div>
                </div>
            </div> -->
        </div>

        <!-- 时间 -->
        <div class="timeList">
            <!-- <div class="son" v-for="(i, index) in timeListInfo" :key="index"
                :style="{ backgroundImage: index == 0 ? 'linear-gradient(to bottom, #FFFFFF, #D7C8C8)' : index == 1 ? 'linear-gradient(to bottom, #FF5C5C, #F54848)' : 'linear-gradient(to bottom, #FA9999, #FF8080)', color: index == 0 ? '#666' : '' }">
                {{ index == 0 ? `${i.seckillHall} ` : index == 1 ? `${i.seckillHall} ` : index == 2 ? `${i.seckillHall} ` :
                    `${i.seckillHall} ` }}
                已结束
            </div> -->
            <div class="son cur" v-for="(i, index) in timeListInfo" :key="index" 
                :class="[ i.typeTime == 0 ? 'wks' : i.typeTime == 1 ? 'jxz' : 'yjs' ]"
                :style="{background: i.isShow ? 'linear-gradient(180deg, #FF5C5C 0%, #F54848 55%, #EF3333 100%)' : '',color:i.isShow ?'#fff' : ''}" >
                <div style="width: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column;"
                    @click="timeClick(i, index)">
                    <div>{{ i.seckillHall }}</div>
                    <div>{{ i.typeTime == 0 ? '未开始' : i.typeTime == 1 ? '进行中' : '已结束' }}</div>
                </div>
            </div>
        </div>

        <!-- 列表 -->
        <div class="list" v-loading="loading">
            <div class="son cur" v-for="(n, index) in timeDataArr" :key="index" @click="spuClick(n)">
                <div class="img">
                    <img :src="n.picUrl" alt="">
                </div>
                <div class="name">{{ n.name }}</div>
                <div class="jiage">
                    <div class="jg">原价{{ n.mallSku?.price }}</div>
                    <div class="num">仅{{n.seckillNum}}件</div>
                    <div class="jdt">
                        <el-progress :percentage="((n.seckillNum / n.limitNum) * 100)" :stroke-width="6" color="#FAAD3A" define-back-color="#D9D9D9"
                            :show-text="false"></el-progress>
                    </div>
                </div>
                <div class="msbg">
                    <img src="@/assets/msbg.png" alt="">
                    <div class="jg">¥ <span>{{ n.seckillPrice }}</span></div>
                    <div class="text" v-if="false">立即抢购</div>
                    <div class="text" v-else style="color: #FF6F68;">{{ n.typeTime == 0 ? '还未开始' : n.typeTime == 1 ? '进行中' : '已结束' }}</div>
                </div>
            </div>

            <!-- 分页 -->
            <!-- <div class="paging">
                <el-pagination background layout="prev, pager, next" :total="100">
                </el-pagination>
            </div> -->
        </div>



        <!-- 底部 -->
        <!-- <bottomContent></bottomContent> -->
    </div>
</template>

<script>
import { eventBus } from '@/main.js'
import { getLimitedTimeFlashKillTimeList, getTimeFlashKilling, getFlashKillingCommodity } from '@/utils/api/homeApi/index'
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
export default {
    name: 'limitedTimeFlashKill',
    components: {
        largeHead,
        bottomContent
    },
    data() {
        return {
            loading:true,
            // 头部激活菜单
            tabArrShow: 9,
            showImg: true,
            percentage: 40,
            // 秒杀时间
            timeListInfo: [],
            // 时间
            seckillHall: '',
            // 开始时间
            startTime: '',
            // 结束时间
            endTime: '',
            // 分页
            paging: {
                sizi: 20,
                page: 1,
                total: 0
            },
            timeData: [],
            timeDataArr: [],
        }
    },
    mounted() {
        this.timeList()
    },
   
    methods: {
        // 跳转事件
        spuClick(i){
            window.open(`#/commodity/productDetails?ids=${i.seckillHallInfo.seckillHallInfoId}&type=MS&typeTime=${i.typeTime}`, '_blank');
        },
        ming() {
            this.showImg = !this.showImg
        },
        // 获取秒杀时间
        async timeList() {
            const res = await getLimitedTimeFlashKillTimeList(1)
            if (res.statusCode == 8201) {
                this.timeListInfo = res.data.slice(-7);
                this.timeListInfo.map(m => {
                    let endTime = new Date(m.mallSeckillHallList[0].endTime).getTime()  // 结束时间
                    let startTime = new Date(m.mallSeckillHallList[0].startTime).getTime() // 开始时间
                    const currentTimestamp = new Date().getTime(); // 当前时间
                    console.log(startTime, endTime, currentTimestamp)
                    if (currentTimestamp < startTime) {
                        this.$set(m, 'typeTime', '0') // 未开始
                    } else if (currentTimestamp > endTime) {
                        this.$set(m, 'typeTime', '2') // 已结束
                    } else {
                        this.$set(m, 'typeTime', '1') // 进行中
                    }
                    this.$set(m, 'isShow', false)
                })
                this.timeListInfo[0].isShow = true
                this.endTime = this.timeListInfo[0].mallSeckillHallList[0].endTime
                this.startTime = this.timeListInfo[0].mallSeckillHallList[0].startTime
                this.getTimeFlash(this.timeListInfo[0].seckillHall)
            }
        },
        timeClick(i, index) {
            this.loading = true
            this.timeListInfo.map(i => i.isShow = false)
            this.timeListInfo[index].isShow = true
            this.seckillHall = i.seckillHall
            this.endTime = i.mallSeckillHallList[0].endTime
            this.startTime = i.mallSeckillHallList[0].startTime
            this.getTimeFlash(i.seckillHall)
            console.log(i)
        },
        async getTimeFlash(time) {
            const res = await getTimeFlashKilling({ time, seckillType: 1 })
            if (res.statusCode == 8201) {

                res.data[0].mallSeckillHallList.map(async y => {
                    this.timeData = []
                    let dataObj = {}
                    const rej = await getFlashKillingCommodity({
                        current: this.paging.page,
                        size: this.paging.sizi,
                        id: y.seckillHallId
                    })
                    if (rej.statusCode == 8201) {
                        let endTime = new Date(this.endTime).getTime()  // 结束时间
                        let startTime = new Date(`${this.seckillHall} ${this.formatTime(y.hallTime)}`).getTime() // 开始时间
                        const currentTimestamp = new Date().getTime(); // 当前时间
                        if (currentTimestamp < startTime) {
                            dataObj.typeTime = 0
                        } else if (currentTimestamp > endTime) {
                            dataObj.typeTime = 2
                        } else {
                            dataObj.typeTime = 1
                        }
                        dataObj.hallTime = this.formatTime(y.hallTime)
                        dataObj.newArr = rej.data.records
                        this.timeData.unshift(dataObj)
                        this.timeDataArr = []
                        this.timeData.map(n => {
                            n.newArr.map(u => {
                                this.$set(u, 'typeTime', n.typeTime)
                                this.$set(u, 'hallTime', n.hallTime)
                                this.timeDataArr.push(u)
                            })
                        })
                        console.log(this.timeDataArr, 'this.timeDataArr')
                        this.loading = false
                    }
                })

            }
        },
        // 日期转换
        formatTime(num) {
            const hours = Math.floor(num / 60);
            const minutes = num % 60;
            const hoursStr = hours < 10 ? `0${hours}` : String(hours);
            const minutesStr = minutes < 10 ? `0${minutes}` : String(minutes);
            return `${minutesStr}:${hoursStr}`;
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    // 头部大图片
    >.headImg {
        width: 1200px;
        height: 170px;
        margin: 20px auto;

        >img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    // tabs
    >.tabs {
        width: 1200px;
        height: auto;
        margin: 0 auto;
        cursor: pointer;
        margin-top: -60px;

        >.title {
            width: 400px;
            display: flex;

            >.img {
                width: 166px;
                height: 68px;
                background: url('../assets/ms1A.png');
                z-index: 2;

                >.text {
                    font-size: 24px;
                    font-weight: bold;
                    color: #fff;
                    text-align: center;
                    line-height: 68px;
                }
            }

            >.img2 {
                width: 183px;
                height: 55px;
                background: url('../assets/ms2.png') no-repeat;
                background-size: 100% 100%;
                margin-top: 12px;
                margin-left: -35px;
                z-index: 1;

                >.text {
                    font-size: 20px;
                    color: #fff;
                    text-align: center;
                    line-height: 54px;
                }
            }
        }

        >.title2 {
            width: 400px;
            display: flex;

            >.img {
                width: 166px;
                height: 68px;
                background: url('../assets/ms1.png') no-repeat;
                background-size: 100% 100%;
                z-index: 1;

                >.text {
                    font-size: 24px;
                    font-weight: bold;
                    color: #fff;
                    text-align: center;
                    line-height: 68px;
                }
            }

            >.img2 {
                width: 183px;
                height: 55px;
                background: url('../assets/ms2A.png') no-repeat;
                background-size: 100% 100%;
                margin-top: 12px;
                margin-left: -35px;
                z-index: 2;

                >.text {
                    font-size: 20px;
                    color: #fff;
                    text-align: center;
                    line-height: 54px;
                }
            }
        }
    }

    // 时间列表
    >.timeList {
        width: 1200px;
        height: 106px;
        margin: 0 auto;
        background-image: linear-gradient(to bottom, #FFDEBC, #FFFFFF);
        padding: 25px 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        gap: 30px;

        >.son {
            width: 140px;
            height: 56px;
            border-radius: 40px;
            // background-image: linear-gradient(to bottom, #FFFFFF, #D7C8C8);
            // background-image: linear-gradient(rgb(255, 92, 92), rgb(245, 72, 72));
            box-shadow: 0px 2px 8px 0px rgba(255, 80, 24, 0.3);
            text-align: center;
            font-size: 16px;
            // color: #fff;
            display: flex;
            align-items: center;
        }
    }

    // 列表
    .list {
        width: 1200px;
        height: auto;
        background-color: #fff;
        margin: 35px auto;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        // justify-content: space-between;
        gap: 22px;
        flex-wrap: wrap;

        >.son {
            width: 210px;
            height: auto;
            margin-bottom: 15px;

            >.img {
                width: 210px;
                height: 210px;

                >img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            >.name {
                white-space: nowrap;
                /* 文本不换行 */
                overflow: hidden;
                /* 溢出隐藏 */
                text-overflow: ellipsis;
                /* 添加省略号 */
                font-size: 14px;
                color: #333;
            }

            >.jiage {
                width: 100%;
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #666;
                margin-top: 5px;

                >.jg {
                    text-decoration: line-through;
                }

                >.num {
                    margin-left: 20px;
                }

                >.jdt {
                    width: 60px;
                    margin-left: 3px;
                }
            }

            >.msbg {
                width: 210px;
                height: 30px;
                margin-top: 10px;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;

                >img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 0;
                }

                >.jg {
                    color: #fff;
                    font-size: 12px;
                    margin-left: 10px;
                    z-index: 999;

                    >span {
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                >.text {
                    color: #E1241B;
                    font-size: 14px;
                    margin-right: 12px;
                    z-index: 999;
                    font-weight: bold;
                }
            }
        }
    }

    // 分页
    .paging {
        width: 1200px;
        height: 50px;
        margin: 30px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0px;
    }

}

.yjs {
    background: linear-gradient(180deg, #FFFFFF 0%, #D7C8C8 55%, #CCC0C0 100%) ;
    color: #666666 ;
}
.jxz {
    background: linear-gradient(180deg, #FF8282 0%, #FF7676 55%, #FF6767 100%) ;
    color: #fff ;
}
.wks {
    background: linear-gradient(180deg, #FFAD62 0%, #FFA857 55%, #F47E3C 100%) ;
    color: #fff ;
}
.act {
    background: linear-gradient(180deg, #FF5C5C 0%, #F54848 55%, #EF3333 100%) ;
    color: #fff ;
}
// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>